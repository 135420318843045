import React from "react";

function Partners() {
  return (
    <>
      {/* <div className="layout__inner"> */}
      <h2 className="text--center text--color--tertiary">
        Naši partneri &#10084;
      </h2>
      <p className="padding-top-1">
        Sme veľmi radi, že v Košiciach a okolí máme silný okruh spriaznených
        organizácií, ktoré s nami spolupracujú a neraz vo veľkom pomáhajú. Každá
        z nich robí pre svoje okolie neoceniteľnú službu sama o sebe a je
        úžasné, že si okrem toho nájdu čas, zdroje, energiu aby nás rôznou
        formou podporili. Súčasťou našej filozofie je tiež spolupracovať s
        organizáciami, iniciatívami či súkromnými firmami, ktoré svojou
        činnosťou obohacujú spoločnosť, nesú v sebe pridanú hodnotu. Okrem toho
        sú tu tiež organizácie, ktoré nám poskytli dotáciu alebo grant a my im
        ďakujeme za to, že dávajú takúto šancu nám i iným.{" "}
      </p>
      <div className="layout__partners partners margin-top-1">
        <img
          src="../../images/partneri/partneri-01.jpeg"
          alt=""
          className="partners__img img-1"
        />
        <img
          src="../../images/partneri/partneri-02.jpeg"
          alt=""
          className="partners__img img-2"
        />
        <img
          src="../../images/partneri/partneri-03.jpeg"
          alt=""
          className="partners__img img-3"
        />
        <img
          src="../../images/partneri/partneri-04.jpeg"
          alt=""
          className="partners__img img-4"
        />
        <img
          src="../../images/partneri/partneri-05.jpeg"
          alt=""
          className="partners__img img-5"
        />
        <img
          src="../../images/partneri/partneri-06.png"
          alt=""
          className="partners__img img-6"
        />
        <img
          src="../../images/partneri/partneri-07.png"
          alt=""
          className="partners__img img-7"
        />
        <img
          src="../../images/partneri/partneri-08.png"
          alt=""
          className="partners__img img-8"
        />
      </div>
      {/* </div> */}
    </>
  );
}

export default Partners;
