export const homeObjAbout = {
  id: 0,
  topLine: "O nás",
  headline: "Keď môžem predstavím sa",
  description:
    "Sme skupina ľudí, ktorá rada venuje energiu zmysluplným projektom. S vášňou v srdci podporujeme rozmanitosť a spoločné úsilie.",
  buttonLabel: "Viac",
  img: "../../images/hp-aboutus.png",
  alt: "Hand",
  headlineColor: "",
  descriptionColor: "tertiary",
  path: "/o-nas",
};

export const homeObjContact = {
  id: 1,
  topLine: "Kontakt",
  headline: "Keď môžeš ozvi sa",
  description:
    "Nič nám nerobí väčšiu radosť než spoznávať nové dobré srdcia, ktoré majú ochotu robiť niečo pre druhých a teda i pre seba. Kľudne nám napíš a my sa ti ozveme.",
  buttonLabel: "Kontaktuj",
  img: "../../images/hp-girl.png",
  imgSize: "img-size",
  alt: "deliver",
  headlineColor: "gl-white",
  descriptionColor: "tertiary",
  path: "/kontakt",
};

export const homeObjDonate = {
  id: 2,
  topLine: "Podpor nás",
  headline: "Keď môžeš podporíš",
  description:
    "Ak máš nejaký ten finančný prebytok a hľadáš, kde by si ho rozumne daroval, ponúkame ti možnosť.",
  buttonLabel: "Podpor",
  img: "../../images/hp-boy.png",
  imgSize: "img-size",
  imgBg: "../../",
  alt: "donate",
  headlineColor: "",
  descriptionColor: "tertiary",
  path: "/podpor-nas",
};
