import { ReactComponent as BreakerIcon } from "../../images/icons/breaker.svg";

import React from "react";

function BreakerElement() {
  return (
    <div className="breaker-element">
      <BreakerIcon />
    </div>
  );
}

export default BreakerElement;
