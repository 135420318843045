import React from "react";

function ErrorPage() {
  return (
    <>
      <div className="layout">
        <h1 className="h1 page-header page-header--bg-color--gl-red page-header--color--gl-white">
          Error Page
        </h1>
      </div>
      <div className="layout__inner"></div>
    </>
  );
}

export default ErrorPage;
