export const contentData = {
  intro: {
    title: "O nás",
    annotation: `Sme komunita ľudí, ktorá rada venuje energiu zmysluplným projektom. S vášňou v srdci podporujeme rozmanitosť a spoločné úsilie. Radi prepájame rôzne komunity, vytvárame spolupráce a neformálne vzdelávanie seba a iných. Naše základy sú pohyb, komunita, príroda a sociálno. Keď to premiešame s naším vrodeným optimizmom, radosťou zo života, láskou k prírode a zalejeme kvalitnými spoluprácami, prichádzajú výsledky.`,
    description: `Našu komunitu tvoria rôznorodí ľudia s rôznym vekom, pohlaviami, povolaniami či osudmi. Veríme v dynamiku teamu a vieme, že raz zatiahne jeden, inokedy druhý, občas všetci naraz. Každý vie niečo a spolu vieme viac. Život je zmena a neustála transformácia, a tak to máme aj my v komunite. Radi cestujeme, spoznávame nové svety a vesmíri. Radi sa však i vraciame a organizujeme dobré veci pre seba či iných.`,
    mission: `Naša filozofia je šíriť pozitívneho ducha a optimizmus. Veríme, že tento svet a ľudia v ňom sú múdri a dobrí, nech vyzerajú akokoľvek a prichádzajú odkiaľkoľvek. Veríme, že príroda je nenahraditeľná a treba ju chrániť. Veríme, že pohyb je dôležitý, cvičí naše telá a očisťuje hlavy. Veríme, že nám treba i kultúru, lebo tá pre zmenu zveľaďuje dušu.`,
    logoDesc: `Naše logo vystihuje našu filozofiu. Všetko so všetkým súvisí, všetko je spolu prepojené. Nedá sa starať o jednu vec bez toho, aby sme sa starali tú druhú. Toto logo nám pripravila skvelá grafička Katka Rybnická a vieme, že to celé vystihla.`,
  },
  introEnglish: {
    annotation: `<strong>Keď môžem pomôžem</strong> is a local NGO focused on social inclusion, ecology, sport and community.`,
    description: `Our organization is focused on supporting all kinds of community way of life. We are organizing sport, cultural, educational and other kind of events to support community ideas. We are also including marginalized social groups in our activities to make them taking part in our society. Our important goal is also to provide environmental awareness and to spread zero waste ideas in the city of Košice.
    <a href="https://twitter.com/KPomozem" target="__blank">Learn more</a>.`,
  },
};
