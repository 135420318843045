export const articles = [
  {
    id: 0,
    title: "Keď môžem športujem",
    text: `Pod týmto projektom si predstav pohyb, radosť, pohodu a inklúziu naraz. Kostrou projektu sú športovania pre deti a mládež zo znevýhodneného prostredia, na ktoré však majú dvere otvorené všetci a môžu tak týchto mladých spoznať. Dávame tak šancu na kvalitne strávený čas pre všetky zúčastnené skupiny. Samozrejme po každom športovaní nemôže chýbať Tlačovka, kde si pri občerstvení povieme o čom vlastne život je a či to vlastne vôbec vieme. Začať s projektom sme mohli vďaka štedrým nórskym fondom AFC Funds a Karpatskej nadácii, za čo im budeme navždy vďační.`,
    gallery: [
      {
        id: 0,
        name: "športujeme",
        image: "../../images/project-sportujem/01.jpg",
      },
      {
        id: 1,
        name: "športujeme",
        image: "../../images/project-sportujem/02.jpg",
      },
      {
        id: 2,
        name: "športujeme",
        image: "../../images/project-sportujem/03.jpg",
      },
      {
        id: 3,
        name: "športujeme",
        image: "../../images/project-sportujem/04.jpg",
      },
      {
        id: 4,
        name: "športujeme",
        image: "../../images/project-sportujem/05.jpg",
      },
      {
        id: 5,
        name: "športujeme",
        image: "../../images/project-sportujem/06.jpg",
      },
      {
        id: 6,
        name: "športujeme",
        image: "../../images/project-sportujem/07.jpg",
      },
      {
        id: 7,
        name: "športujeme",
        image: "../../images/project-sportujem/08.jpg",
      },
      {
        id: 8,
        name: "športujeme",
        image: "../../images/project-sportujem/09.jpg",
      },
      {
        id: 9,
        name: "športujeme",
        image: "../../images/project-sportujem/10.jpg",
      },
    ],
  },
  {
    id: 1,
    title: "Majstrovstvá v pohodovom športovaní",
    text: `Naša každoročná oslava športu, priateľstva a pohody. Súťaží sa vo viacerých disciplínach, ale vôbec nezáleží či ti daný šport sedí alebo nie. Dôležité je odhodlanie, radosť z pohybu a hry.  Okrem športov sa totiž snažíme pripraviť takú atmosféru, aby si zabudol na všetky nepríjemnosti sveta a v duchu kalokagatie rozvíjal svoju duchovnú i telesnú schránku so všetkým, čo k tomu patrí. Táto akcia trvá celý deň a pohodovo na nej súťažia veľmi rozmanité družstvá. Od detí z Luníka IX, zahraničných študentov až po partiu z Tabačky. Starí či mladí. A presne o tom nám ide, nech si môže každý z vás vychutnať deň plný športu a pohody pre všetkých. Vrelo odporúčame túto udalosť ochutnať.`,

    gallery: [
      {
        id: 0,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-05.jpg",
      },
      {
        id: 1,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-02.jpg",
      },
      {
        id: 2,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-03.jpg",
      },
      {
        id: 3,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-04.jpg",
      },
      {
        id: 4,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-01.jpg",
      },
      {
        id: 5,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-06.jpg",
      },
      {
        id: 6,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-07.jpg",
      },
      {
        id: 7,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-08.jpg",
      },
      {
        id: 8,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-09.jpg",
      },
      {
        id: 9,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-10.jpg",
      },
      {
        id: 10,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-11.jpg",
      },
      {
        id: 11,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-12.jpg",
      },
      {
        id: 12,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-13.jpg",
      },
      {
        id: 13,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-14.jpg",
      },
      {
        id: 14,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-15.jpg",
      },
      {
        id: 15,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-16.jpg",
      },
      {
        id: 16,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-17.jpg",
      },
      {
        id: 17,
        name: "športujeme",
        image: "../../images/project-majstrovstva/majstrovstva-18.jpg",
      },
    ],
  },
  {
    id: 2,
    title: "UrbanVčela",
    text: `V našom OZku máme vášnivých milovnikov včiel, a tak sme sa proste museli začať venovať aj tejto činnosti. Skúsili sme dať dva úľe k spriaznenému Kinu Úsmev, avšak dvorček za budovou nebol ideálnym miestom pre tieto zázračné stvorenia. Presunuli sme ich tak na záhradku na Kalvárii, kde pokračujú vo svojom spokojnom živote. Časom by sme radi včelárstvo zaradili do našej osvetovej činnosti a možno tiež ponúkali apiterapiu.`,

    gallery: [
      {
        id: 0,
        name: "vcely",
        image: "../../images/project-vcely/vcely-03.jpg",
      },
      {
        id: 1,
        name: "vcely",
        image: "../../images/project-vcely/vcely-02.jpg",
      },
      {
        id: 2,
        name: "vcely",
        image: "../../images/project-vcely/vcely-01.jpg",
      },
    ],
  },
  {
    id: 3,
    title: "Festival dokumentárnych filmov Jeden Svet Košice",
    text: `Od roku 2020 zastrešujeme v Košiciach festival dokumentárnych filmov s ľudsko-právnou tematikou so slávnou značkou Jeden Svet a sme na to hrdí. Vďaka úžasnej šéfke festivalu Natálii Centkovej a skvelému teamu, ktorý sa rozširuje každým rokom dostáva festival po rokoch vajatania v Košiciach konečne kvalitnú podobu. Jeho program je nabitý skvelými dokumentami, diskusiami a sprievodným programom.
    <p>&nbsp;</p>
    Viac o festivale sa dozviete na jeho samostatnej stránke: <a href="https://www.jedensvetkosice.sk/" target="_blank">www.jedensvetkosice.sk</a>`,

    gallery: [
      {
        id: 0,
        name: "športujeme",
        image: "../../images/project-jedensvet/jedensvet-01.jpg",
      },
    ],
  },
];
