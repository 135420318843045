import React from "react";

function SectionCardMedia({ img }) {
  return (
    <>
      <img src={img} alt="" className="section__img" />
    </>
  );
}

export default SectionCardMedia;
